import React, { Component } from 'react';
import JotformEmbed from 'react-jotform-embed'

class QuoteForm extends Component {
  render() {
    return (
      <JotformEmbed src="https://form.jotform.com/240036477207150" />
    )
  }
}

export default QuoteForm;